<template>
<div>
    <div class="login-div sign-up" style="background-size:contain">
        <!-- <img src="../../assets/images/logo2.png" height="50" class="mb-4">
    <input type="email" class="form-control mb-4" :placeholder="tran.email">
    <input type="password"   class="form-control mb-4"          :placeholder="tran.password">
    <input type="button" class="submit-btn" :value="tran.login">
        <p class="mt-4">{{tran.forgotPassword}}</p> -->

        <div class="row">
            <div class="col-lg-8 col-12">

            </div>
            <div class="col-lg-4 col-12 login-container text-center">
                <div>
                    <img src="../../assets/images/logo.png" height="70" class="auth-img" @click="sendToMarket">
                </div>
                    <div class="form-div">
                        <div class="mb-4">

                            <span class="head-font">{{tran.ref_code}}</span> : <span>{{this.activatorSignUp.ref}}</span>
                        </div>
                        <div class="form-group">
                            <label class="form-label" @click.prevent="focusOnInput($event)" for="first">{{tran.firstName}}</label>
                            <input class="form-input" v-model="activatorSignUp.first_name" @keydown.enter.prevent="checkEnterButton" type="text"  autocomplete="off" />
                            </div>
                            <div class="form-group">
                                <label class="form-label" @click.prevent="focusOnInput($event)" for="first">{{tran.lastName}}</label>
                                <input  v-model="activatorSignUp.last_name" @keydown.enter.prevent="checkEnterButton" class="form-input" type="text"  autocomplete="off" />
                                 </div>
                                <div class="form-group">
                                    <label class="form-label" @click.prevent="focusOnInput($event)" for="last">{{tran.email}}</label>
                                    <input  v-model="activatorSignUp.email" class="form-input" type="email" autocomplete="false" @keydown.enter.prevent="checkEnterButton" />
                                </div>
                                    <div class="form-group">
                                        <label class="form-label" @click.prevent="focusOnInput($event)" for="last">{{tran.phone}}</label>
                                        <input  v-model="activatorSignUp.phone" id="number" class="form-input" type="number" autocomplete="none" maxlength="11" @keydown.enter.prevent="checkEnterButton" />
                                        </div>

                                        <!-- Ref Code -->
                                        <div class="form-group">
                                            <label class="form-label" @click.prevent="focusOnInput($event)" for="last">{{tran.referral_code}}</label>
                                            <input  v-model="activatorSignUp.ref" class="form-input" type="text" autocomplete="none" maxlength="11" @keydown.enter.prevent="checkEnterButton" />
                                        </div>

                                            <div class="custom-control text-center custom-checkbox mt-1 mb-2 my-1 mr-sm-2">
                                                <input type="checkbox" class="custom-control-input" id="customControlInline" checked @click="checkForTerms($event)">
                                                <label class="custom-control-label" for="customControlInline"><b><span>{{tran.i_agree}}</span> 
                                                          <router-link to="/terms" target="_blank">
                                                                     <b style="cursor:pointer; text-decoration:underline">{{tran.terms}}</b>
                                                           </router-link>

                                                                    </b>&nbsp;<span>{{tran.and}}&nbsp;</span>

                                                                       <b> <router-link to="/privacy" target="_blank">

                                                                    <span  style="cursor:pointer; text-decoration:underline"> {{tran.privacy_policy}}</span> 
                                                                        </router-link></b>
                                                                     </label>
                                            </div>
                                            <div class="form-group">
                                                <button type="button" class="submit-btn mt-2" :class="{'fade-elem': loader || !termsPrivacy}" @click.prevent="signUpUser"> {{ loader ? '' :  tran.signup }}  <span class="loader mt-1" v-if="loader"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
</template>

<script>
import trans from '../../../translations'
import {
    mapState
} from "vuex";
import http from "../../http/index";
import api from "../../apis/index";
import {
    userNotification
} from "../dashboard/mixins/notifyUser.js";
export default {
    mixins: [userNotification],
    data() {

        return {
            tran: {},
            termsPrivacy: true,
            loader: false,
            activatorSignUp: {
                email: null,
                first_name: null,
                last_name: null,
                phone: null,
                ref: null

            }
        }
    },
    computed: {
        ...mapState({

            token: state => state.auth.token
        }),
    },

    methods: {

        signUpUser() {
            this.loader = true
            let vm = this
            // localStorage.setItem("userEmail", state.signUp.email);

            http
                .post(api.activatorApi, vm.activatorSignUp)
                .then(response => {
                    vm.loader = false

                    vm.activatorSignUp = {
                        email : null,
                        first_name : null,
                        last_name : null,
                        phone : null,
                        ref : vm.$route.query.ref,

                    }

                    this.$helpers.log(response);

                    this.$toast.success({
                        title: 'success',
                        timeOut: 6000,
                        icon: require("../../assets/images/successicon.png"),
                        message: 'Form Submitted successfully',
                        position: "top right"
                    });
                })
                .catch(error => {
                    vm.loader = false
                             this.$helpers.log(error.response);
                                this.$toast.warn({
          title: this.tran.sorry,
          icon: require("../../assets/images/cautionicon.png"),
          timeOut: 5000,
          message:  error.response.data.message,
          position: "top center"
        });
                });

        },
        checkEnterButton() {
            if (event.keyCode === 13) {
                this.signUpUser();
            }
        },
        showInputText() {
            this.hideInput = 'text';
            this.showEye = false
            this.hideEye = true
        },

        sendToMarket(){
            this.$router.push('/market')
        },


        focusOnInput(e) {

            e.target.parentNode.querySelector('.form-input').focus();
        },
        hideInputText() {
            this.hideInput = 'password';
            this.showEye = true
            this.hideEye = false
        },
        sendToLogin() {

            this.$router.push('/login')
        },

        checkForTerms(event) {

            if (event.target.checked) {

                this.termsPrivacy = true
            } else {
                this.termsPrivacy = false

            }

        },
    },

    mounted() {

        this.tran = trans

this.activatorSignUp.ref = this.$route.query.ref

        this.$helpers.log(this.$route.query, 'query param')
        if (this.$route.query !== undefined) {

            this.$store.commit('auth/saveSignUpQuery', this.$route.query);

        }

    }

}
</script>

<style scoped>
a {

    display: inline;
    color: #000;
    padding: 0px;
}

@media (max-width: 767px) {
    .custom-control label {
        padding-top: 3px;
        font-size: 13px;
    }
}
</style>
