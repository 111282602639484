<template>
    <Activator/>
</template>

<script>
import Activator from '../../components/auth/ActivatorSignUp'
export default {

    components: {
        Activator
    }
}
</script>

<style>

</style>
